import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { Link } from "gatsby"

const NotFoundPage = () => {

  return (
    <Layout>
      <Seo Sitetitle="404: Not found" />
      <div className="container text-center">
        <h1 className="my-4">404!</h1>
        <Link to="/">
          <button className="btn btn-yellow">Home</button>
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
